import { graphql } from 'gatsby';
import { LayoutProps, withLayout } from '../components/Layout';
import React from 'react';
import Helmet from 'react-helmet';
import styled, { css } from '../styled-components';
import {
    Constrain,
    CONSTRAIN_SIZING,
    BaseAnchorStyles,
    BaseProseStyles,
    pullBackWide
} from '../components/ui';
import { media, pixelToRem } from '../utilities';
import { FixedMasthead } from '../components/Masthead/FixedMasthead.component';
import CallToContact from '../components/CallToContact/CallToContact.component';
import { ImageWithShapeOutline } from '../components/ImageWithShapeOutline/ImageWithShapeOutline.component';
import { PageHeader } from '../components/PageHeader/PageHeader.component';
import { BannerAdvert } from '../components/BannerAdvert/BannerAdvert.component';
import { MarkdownRemark, QuerySiteArgs } from '../generated/graphql-types';
import wmwSquareLogo from '../images/icon.png';

interface IArticleProps extends LayoutProps {
    data: {
        article: MarkdownRemark;
        site: QuerySiteArgs;
    };
}

const PullToConstrain = (CONSTRAIN_SIZING.normal - CONSTRAIN_SIZING.narrow) / 2;

const Article = styled.article`
    margin-bottom: ${pixelToRem(16)};

    ${media.xl`
        min-height: 75vh;
    `}
`;

const ArticleBody = styled.div<{ highlightColor: wmw.ColorOptions }>`
    padding: 0 0 ${pixelToRem(24)} 0;

    ${({ theme, highlightColor }) => css`
        ${BaseProseStyles}

        a {
            ${BaseAnchorStyles}

            &:hover {
                text-decoration-color: ${theme.colors[highlightColor].color};
            }
        }

        strong {
            font-weight: 500;
        }

        .tweet-image {
            display: block;
            max-width: 550px;
            margin: 40px auto;
        }

        .pull-quote {
            position: relative;
            margin: 0;
            margin-top: ${pixelToRem(16)};
            padding: ${pixelToRem(16)} ${pixelToRem(16)} ${pixelToRem(16)} 0;
            color: ${theme.colors.deepBlue.contrast};
            margin-bottom: ${theme.spacing.s};
            font-size: ${theme.fontNew.primary.size.level5.size};
            line-height: ${theme.fontNew.primary.size.level5.lineHeight};

            ${media.m`
                width: calc(50% + ${pixelToRem(PullToConstrain)});
                float: left;
                margin-right: ${pixelToRem(32)};
                margin-top: ${pixelToRem(8)};
            `}

            ${media.l`
                margin-left: ${pullBackWide};
            `}

            ${media.xl`
                padding: ${pixelToRem(32)} ${pixelToRem(32)} ${pixelToRem(
                32
            )} 0;
                margin-bottom: ${theme.spacing.m};
                font-size: ${theme.fontNew.primary.size.level4.size};
                line-height: ${theme.fontNew.primary.size.level4.lineHeight};
                padding: ${pixelToRem(32)} ${pixelToRem(32)} ${pixelToRem(
                32
            )} 0;
                margin-left: -${pixelToRem(PullToConstrain)};
            `}

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: -1000px;
                left: -1000px;
                bottom: 0;
                background-color: ${theme.colors.deepBlue.color};
                z-index: -1;

                ${media.m`
                    left: -9999px;
                    right: 0;
                `}
            }

            a {
                color: white;
                text-decoration-color: white;

                &:hover {
                    text-decoration-color: ${theme.colors.electricBlue.color};
                }
            }

            p {
                position: relative;
                margin: 0;

                &:before,
                &:after {
                    content: '“';
                    position: absolute;
                    font-family: ${theme.fontNew.secondary.type};
                    font-size: ${pixelToRem(30)};

                    ${media.m`
                        font-size: ${pixelToRem(40)};
                    `}
                }

                &:before {
                    left: -0.4em;
                    top: -0.4em;
                }

                &:after {
                    content: '”';
                    margin-left: 5px;
                    bottom: -0.4em;
                }
            }
        }

        ul > li:before {
            color: ${theme.colors[highlightColor].color};
        }

        hr {
            background-color: ${theme.colors[highlightColor].color};
        }

        .indent {
            border-color: ${theme.colors[highlightColor].color};
        }

        .pull-image-left,
        .pull-image-right {
            width: 100%;
            margin-bottom: 1rem;

            ${media.m`
                max-width: 50%;
                margin-bottom: 0.5rem;

                & ~ h2,
                & ~ h3 {
                    clear: both;
                }
            `}
        }
    `}
    
    .pull-image-right {
        ${media.m`
            float: right;
            margin-left: 2rem;
        `};
        
        ${media.xl`
            margin-right: ${pullBackWide};
        `}
    };

    .pull-image-left {
        ${media.m`
            float: left;
            margin-right: 2rem;
        `};
        
        ${media.xl`
            margin-left: ${pullBackWide};
        `}
    };




`;

const ArticlePage = (props: IArticleProps) => {
    const {
        frontmatter,
        html,
        plainText,
        fields,
        timeToRead /* could be a nice addition */
    } = props.data.article;

    const { siteUrl } = props.data.site.siteMetadata;

    const image = frontmatter.darkImage && frontmatter.darkImage.publicURL;
    const shapeOutline =
        frontmatter.articleIllustrationOutlineShape &&
        frontmatter.articleIllustrationOutlineShape.publicURL;
    const socialImage =
        frontmatter.socialImage && frontmatter.socialImage.publicURL;

    return (
        <div>
            <Helmet>
                <title>{`${frontmatter.title}`}</title>
                <meta
                    name="description"
                    content={frontmatter.metaDescription}
                />

                <meta name="twitter:dnt" content="on" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@wemakewaves" />
                <meta name="twitter:title" content={frontmatter.title} />
                <meta
                    name="twitter:description"
                    content={frontmatter.summary}
                />
                <meta
                    name="twitter:image"
                    content={`${siteUrl}${socialImage}`}
                />

                <meta property="og:title" content={frontmatter.title} />
                <meta property="og:description" content={frontmatter.summary} />
                <meta property="og:url" content={`${siteUrl}${fields.slug}`} />
                <meta
                    property="og:image"
                    content={`${siteUrl}${socialImage}`}
                />

                <script type="application/ld+json">
                    {/* tslint:disable */}
                    {JSON.stringify(
                        {
                            '@context': 'https://schema.org',
                            '@type': 'Article',
                            mainEntityOfPage: {
                                '@type': 'WebPage',
                                '@id': `${siteUrl}${fields.slug}`
                            },
                            headline: frontmatter.title,
                            image: [image],
                            datePublished: frontmatter.date,
                            dateModified: frontmatter.date,
                            author: {
                                '@type': 'Organization',
                                name: 'We Make Waves'
                            },
                            publisher: {
                                '@type': 'Organization',
                                name: 'We Make Waves',
                                logo: {
                                    '@type': 'ImageObject',
                                    url: wmwSquareLogo
                                }
                            },
                            description: frontmatter.metaDescription,
                            articleBody: plainText
                        },
                        null,
                        2
                    )}
                    {/* tslint:enable */}
                </script>
            </Helmet>

            <FixedMasthead />

            <BannerAdvert />

            <Article>
                <PageHeader
                    highlightColor="electricBlue"
                    title={frontmatter.title}
                    lineTitle={frontmatter.type}
                    imageUrl={image}
                    imageAlt={frontmatter.imageAlt}
                    imageOutlineUrl={shapeOutline}
                />

                <Constrain type="narrow">
                    <ArticleBody
                        dangerouslySetInnerHTML={{ __html: html }}
                        highlightColor="electricBlue"
                    />
                </Constrain>
            </Article>

            <CallToContact />
        </div>
    );
};

export const pageQuery = graphql`
    query TemplateBlogArticle($slug: String!) {
        site {
            siteMetadata {
                siteUrl
            }
        }

        article: markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            plainText
            timeToRead
            fields {
                slug
            }
            frontmatter {
                title
                date
                summary
                metaDescription
                type
                imageAlt
                articleIllustrationOutlineShape {
                    publicURL
                }
                darkImage {
                    publicURL
                }
                socialImage {
                    publicURL
                }
            }
        }
    }
`;

export default withLayout(ArticlePage);
